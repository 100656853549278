import { default as _91_46_46_46slug_93Jm3A2KpYsUMeta } from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/[...slug].vue?macro=true";
import { default as checkouth92hGqKw8NMeta } from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/checkout.vue?macro=true";
import { default as cookie_45deklarationAXnrFJrSVTMeta } from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/cookie-deklaration.vue?macro=true";
import { default as glemt_45kodeorduaLiB7V4ZcMeta } from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/glemt-kodeord.vue?macro=true";
import { default as logind0HcZb6oxOiMeta } from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/logind.vue?macro=true";
import { default as _91slug_93tWUffiftH5Meta } from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/mitaller/[slug].vue?macro=true";
import { default as indextZXLbNVy9bMeta } from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/mitaller/index.vue?macro=true";
import { default as nyt_45kodeordNBBQ5ToIrRMeta } from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/nyt-kodeord.vue?macro=true";
import { default as opretXkMc4f06KdMeta } from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/opret.vue?macro=true";
import { default as prereceiptaB4TiVDB05Meta } from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/prereceipt.vue?macro=true";
import { default as reklamationN64xpKOc5yMeta } from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/reklamation.vue?macro=true";
import { default as tak_45for_45din_45bestillinglwh3WnSuaXMeta } from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/tak-for-din-bestilling.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93Jm3A2KpYsUMeta || {},
    component: () => import("/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/[...slug].vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    component: () => import("/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/checkout.vue")
  },
  {
    name: "cookie-deklaration",
    path: "/cookie-deklaration",
    component: () => import("/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/cookie-deklaration.vue")
  },
  {
    name: "glemt-kodeord",
    path: "/glemt-kodeord",
    component: () => import("/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/glemt-kodeord.vue")
  },
  {
    name: "logind",
    path: "/logind",
    component: () => import("/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/logind.vue")
  },
  {
    name: "mitaller-slug",
    path: "/mitaller/:slug()",
    meta: _91slug_93tWUffiftH5Meta || {},
    component: () => import("/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/mitaller/[slug].vue")
  },
  {
    name: "mitaller",
    path: "/mitaller",
    meta: indextZXLbNVy9bMeta || {},
    component: () => import("/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/mitaller/index.vue")
  },
  {
    name: "nyt-kodeord",
    path: "/nyt-kodeord",
    component: () => import("/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/nyt-kodeord.vue")
  },
  {
    name: "opret",
    path: "/opret",
    component: () => import("/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/opret.vue")
  },
  {
    name: "prereceipt",
    path: "/prereceipt",
    component: () => import("/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/prereceipt.vue")
  },
  {
    name: "reklamation",
    path: "/reklamation",
    component: () => import("/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/reklamation.vue")
  },
  {
    name: "tak-for-din-bestilling",
    path: "/tak-for-din-bestilling",
    component: () => import("/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/tak-for-din-bestilling.vue")
  }
]