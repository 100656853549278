import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w2VlvAEcpa from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import api_dQSKrs3iES from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/node_modules/@aller/nuxt-allerservice-sdk/plugins/api.ts";
import defaults_3VzMw3SfPd from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/node_modules/@aller/nuxt-allerservice-sdk/plugins/defaults.ts";
import allerAds_client_DNqphmSVuC from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/node_modules/@aller/nuxt-base/plugins/allerAds.client.ts";
import allerChunkReload_client_MuA6glRHs1 from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/node_modules/@aller/nuxt-base/plugins/allerChunkReload.client.ts";
import allerCookiebot_client_Ys5gNO0tjc from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/node_modules/@aller/nuxt-base/plugins/allerCookiebot.client.ts";
import allerInterpolation_F5VNU7nTNl from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/node_modules/@aller/nuxt-base/plugins/allerInterpolation.ts";
import allerObserve_AKm9a1hrmZ from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/node_modules/@aller/nuxt-base/plugins/allerObserve.ts";
import allerPPID_client_z1Q55MS9C9 from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/node_modules/@aller/nuxt-base/plugins/allerPPID.client.ts";
import allerservicedefaults_kKyv6ftnUW from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/plugins/allerservicedefaults.ts";
import instafix_xPa6aSyzPL from "/codebuild/output/src1713159848/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/plugins/instafix.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_w2VlvAEcpa,
  api_dQSKrs3iES,
  defaults_3VzMw3SfPd,
  allerAds_client_DNqphmSVuC,
  allerChunkReload_client_MuA6glRHs1,
  allerCookiebot_client_Ys5gNO0tjc,
  allerInterpolation_F5VNU7nTNl,
  allerObserve_AKm9a1hrmZ,
  allerPPID_client_z1Q55MS9C9,
  allerservicedefaults_kKyv6ftnUW,
  instafix_xPa6aSyzPL
]